/** @jsx jsx */
import { css } from '@emotion/core';
import { enFont01 } from "../../constants/styleConstants";

export const blockTitleStyle = {
  block: css`
    ${enFont01.bold};
    font-size: 1.875rem;
    text-align: center;
    margin-bottom: 3rem;
  `,
}
