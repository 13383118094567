import React from "react"
// import MainVisualImgBg01 from "../../images/top/8321370F8DFC76F1.jpeg";
// import MainVisualImgBg02 from "../../images/top/8321370F8DFC76F3.jpeg";
import MainVisualImgBg01 from "../../images/top/20_0928_kawshima_16859.jpg";
import MainVisualImgBg02 from "../../images/top/20_0928_kawshima_16900.jpg";
import MainVisualImgBg03 from "../../images/top/20_1020_kawshima_17040.jpg";

const TopMainVisualImage = () => {

  return (
    <div>
      <svg version="1.1" id="top_x5F_image" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
           y="0px" width="739px" height="409px" viewBox="0 0 739 409" enableBackground="new 0 0 739 409" xmlSpace="preserve">
        <g>
          <defs>
            <path id="SVGID_11_" d="M21.61,173.151L0.216,157.527L77.756,51.35L99.15,66.973L21.61,173.151z M10.435,408.501h26.492V277.024
			H10.435V408.501z M109.534,408.501h26.492V277.024h-26.492V408.501z M299.347,196.279h40.404v26.492h-40.404v39.539l15.516-25.119
			l22.539,13.922l-38.055,61.608v69.29h40.404v26.492h-40.404h-26.492V382.01v-26.402l-31.842,51.55v1.343h-26.492h-39.374h-26.492
			V382.01v-50.414v-26.492v-30.933l-9.062-13.764H89.42v123.682H62.928V260.407H10.435v-26.492h7.135l71.96-98.536l21.394,15.624
			l-60.55,82.913h71.779l-19.044-28.926l22.126-14.568l3.857,5.858h19.565v-86.651h-20.101V83.137h52.493V38.949h26.492v44.189
			h49.696v26.492h-16.221v86.651h31.842V38.949h26.492V196.279z M175.148,196.279h39.374v-86.651h-6.983h-26.492h-5.9V196.279z
			 M197.533,300.235l-7.395,4.869h24.384v-44.698h-39.374v5.827L197.533,300.235z M214.522,382.01v-50.414h-39.374v50.414H214.522z
			 M272.856,222.771H146.533l7.337,11.144h21.278h39.374h26.492v26.492v44.698v26.492v25.152l31.842-51.55V222.771z M313.902,51.35
			v111.921h26.492V51.35H313.902z"/>
          </defs>
          <clipPath id="SVGID_1_">
            <use xlinkHref="#SVGID_11_"  overflow="visible"/>
          </clipPath>
          <g clipPath="url(#SVGID_1_)">

            <image overflow="visible" width="2000" height="3000" id="_x32_0_x5F_0928_x5F_kawshima_x5F_16859.tif_2_" xlinkHref={MainVisualImgBg01} transform="matrix(0.2423 0 0 0.2423 0.7163 -25.3019)">
            </image>
          </g>
        </g>
        <g>
          <defs>
            <path id="SVGID_13_" d="M257.822,122.221l-21.38-15.613l77.49-106.109l21.38,15.613L257.822,122.221z M361.38,139.48
			l-22.112,14.558l19.032,28.907h-71.732l60.511-82.859l-21.38-15.613l-71.913,98.472h-7.129v26.474h52.458v123.601h26.474V209.42
			h50.141l33.695,51.179v96.819h26.474V226.026H418.36L361.38,139.48z M246.656,357.418h26.474V226.026h-26.474V357.418z
			 M345.69,357.418h26.474V226.026H345.69V357.418z M498.08,16.112L476.7,0.499L399.21,106.607l21.38,15.613L498.08,16.112z
			 M596.4,249.222L524.148,139.48l-22.112,14.558l19.032,28.907h-71.732l60.511-82.859l-21.38-15.613l-71.913,98.472h-7.129v26.474
			h52.458v123.601h26.474V209.42h50.14l35.79,54.361L596.4,249.222z M508.458,357.418h26.474V226.026h-26.474V357.418z"/>
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_13_"  overflow="visible"/>
          </clipPath>
          <g transform="matrix(1 0 0 1 -1.525879e-05 0)" clipPath="url(#SVGID_2_)">

            <image overflow="visible" width="2000" height="3000" id="_x32_0_x5F_0928_x5F_kawshima_x5F_16900.tif_1_" xlinkHref={MainVisualImgBg02} transform="matrix(0.1849 0 0 0.1849 234.4982 -179.7758)">
            </image>
          </g>
        </g>
        <g>
          <defs>
            <path id="SVGID_15_" d="M420.607,172.412l-21.396-15.625l77.549-106.19l21.396,15.625L420.607,172.412z M409.431,407.789h26.495
			V276.297h-26.495V407.789z M508.541,407.789h26.495V276.297h-26.495V407.789z M695.299,50.597h-26.495v138.866h-43.485v26.495
			h43.485v138.866h26.495V215.958h43.485v-26.495h-43.485V50.597z M608.032,50.597h-26.495v226.112l-57.295-87.025l-22.128,14.569
			l19.046,28.929h-71.787l60.557-82.922l-21.396-15.625l-71.968,98.547h-7.135v26.495h52.498v123.696h26.495V259.677h50.18
			l35.817,54.402l7.117-4.686v98.395h26.495v0h130.752v-26.495H608.032V50.597z M712.29,50.597v111.934h26.495V50.597H712.29z
			 M651.813,50.597h-26.495v111.934h26.495V50.597z M712.29,355.981h26.495V244.048H712.29V355.981z M651.813,244.048h-26.495
			v111.934h26.495V244.048z"/>
          </defs>
          <clipPath id="SVGID_3_">
            <use xlinkHref="#SVGID_15_"  overflow="visible"/>
          </clipPath>
          <g id="ame90T_1_" clipPath="url(#SVGID_3_)">

            <image overflow="visible" width="1344" height="2016" id="ame90T_2_" xlinkHref={MainVisualImgBg03} transform="matrix(0.2637 0 0 0.2637 395.3126 -37.9227)">
            </image>
          </g>
        </g>
      </svg>
    </div>
  );
}

const TopMainVisualImageOld = () => {

  const style = `
  .st0{fill:#B1B1B1;}
  .st1{clip-path:url(#SVGID_2_);}
  .st2{clip-path:url(#SVGID_4_);}
  .st3{clip-path:url(#SVGID_6_);}
  .st4{clip-path:url(#SVGID_8_);}
`;

  return (
    <div>
      <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
           x="0px"
           y="0px" width="1163px" height="644px" viewBox="0 0 1163 644" style={{enableBackground:"new 0 0 1163 644"}}
           xmlSpace="preserve">
        <style type="text/css">{style}</style>
        <g>

		<rect x="602.34" y="155.587" transform="matrix(0.5898 -0.8076 0.8076 0.5898 147.0881 642.4775)" className="st0"
          width="207.189" height="41.747"/>

          <rect x="620.908" y="288.012" transform="matrix(0.5898 -0.8076 0.8076 0.5898 47.7628 711.7967)"
                className="st0" width="207.189" height="41.748"/>

          <rect x="846.543" y="294.082" transform="matrix(0.8352 -0.5499 0.5499 0.8352 -75.7495 542.5294)"
                className="st0" width="41.742" height="207.163"/>
          <rect x="644.04" y="368.203" className="st0" width="207.173" height="41.744"/>
          <rect x="726.754" y="397.664" className="st0" width="41.744" height="207.173"/>
          <rect x="644.04" y="436.133" className="st0" width="41.744" height="207.173"/>
          <rect x="800.193" y="436.133" className="st0" width="41.744" height="207.173"/>
          <rect x="984.184" y="299.32" className="st0" width="178.772" height="41.744"/>
          <rect x="955.783" y="601.561" className="st0" width="207.173" height="41.744"/>
          <rect x="1052.698" y="80.528" className="st0" width="41.744" height="479.329"/>
          <rect x="915.205" y="80.528" className="st0" width="41.744" height="562.779"/>
          <rect x="1121.212" y="80.528" className="st0" width="41.744" height="176.359"/>
          <rect x="984.184" y="80.528" className="st0" width="41.744" height="176.359"/>
          <rect x="1121.212" y="385.322" className="st0" width="41.744" height="176.358"/>
          <rect x="984.184" y="385.322" className="st0" width="41.744" height="176.358"/>
</g>
        <g>
	<polygon className="st0" points="379.455,641.333 344.107,619.126 495.867,373.661 531.608,395.225 	"/>
          <rect x="234.124" y="368.499" className="st0" width="145.33" height="41.744"/>
          <rect x="429.674" y="601.856" className="st0" width="105.411" height="41.744"/>
          <rect x="202.354" y="131.056" className="st0" width="202.667" height="41.744"/>
          <rect x="202.354" y="309.25" className="st0" width="332.731" height="41.744"/>
          <rect x="234.124" y="480.378" className="st0" width="145.33" height="41.744"/>
          <rect x="234.124" y="601.856" className="st0" width="145.33" height="41.744"/>
          <rect x="234.124" y="368.499" className="st0" width="41.744" height="275.103"/>
          <rect x="284.861" y="69.302" className="st0" width="41.744" height="103.497"/>
          <rect x="233.874" y="131.056" className="st0" width="41.744" height="219.883"/>
          <rect x="337.711" y="131.056" className="st0" width="41.744" height="219.883"/>
          <rect x="337.711" y="368.499" className="st0" width="41.744" height="274.807"/>
          <rect x="429.521" y="69.302" className="st0" width="41.744" height="574.299"/>
          <rect x="494.353" y="80.527" className="st0" width="41.744" height="176.653"/>

          <rect x="-25.612" y="155.92" transform="matrix(0.5898 -0.8076 0.8076 0.5898 -110.7819 135.5008)"
                className="st0" width="207.189" height="41.747"/>

          <rect x="-7.052" y="288.339" transform="matrix(0.5898 -0.8076 0.8076 0.5898 -210.1059 204.8109)"
                className="st0" width="207.189" height="41.748"/>
          <polyline className="st0" points="276.076,495.957 162.153,322.919 197.018,299.965 310.942,473.002 	"/>
          <rect x="16.124" y="368.499" className="st0" width="207.173" height="41.744"/>
          <rect x="98.839" y="397.96" className="st0" width="41.744" height="207.173"/>
          <rect x="16.124" y="436.429" className="st0" width="41.744" height="207.173"/>
          <rect x="172.277" y="436.429" className="st0" width="41.744" height="207.173"/>
</g>
        <g>

		<rect x="602.119" y="76.751" transform="matrix(0.5898 -0.8076 0.8076 0.5898 210.6629 609.9589)" className="st0"
          width="207.19" height="41.747"/>

          <rect x="620.687" y="209.175" transform="matrix(0.5898 -0.8076 0.8076 0.5898 111.3381 679.2781)"
                className="st0" width="207.19" height="41.748"/>
          <polyline className="st0" points="903.775,416.83 789.852,243.792 824.717,220.837 938.641,393.875 	"/>
          <rect x="643.824" y="289.372" className="st0" width="207.173" height="41.744"/>
          <rect x="726.538" y="318.833" className="st0" width="41.744" height="207.173"/>
          <rect x="643.824" y="357.302" className="st0" width="41.744" height="207.173"/>
          <rect x="799.977" y="357.302" className="st0" width="41.744" height="207.173"/>

          <rect x="346.523" y="76.766" transform="matrix(0.5898 -0.8076 0.8076 0.5898 105.7996 403.5547)"
                className="st0" width="207.19" height="41.747"/>

          <rect x="365.088" y="209.187" transform="matrix(0.5898 -0.8076 0.8076 0.5898 6.4754 472.8698)" className="st0"
                width="207.19" height="41.748"/>
          <polyline className="st0" points="648.194,416.83 534.271,243.792 569.136,220.837 683.06,393.875 	"/>
          <rect x="388.243" y="289.372" className="st0" width="207.173" height="41.744"/>
          <rect x="470.957" y="318.833" className="st0" width="41.744" height="207.173"/>
          <rect x="388.243" y="357.302" className="st0" width="41.744" height="207.173"/>
          <rect x="544.396" y="357.302" className="st0" width="41.744" height="207.173"/>
</g>
        <g>
	<g>
		<g>
			<defs>
				<path id="SVGID_1_" d="M16.146,643.602h41.745V436.428H16.146V643.602z M172.299,643.602h41.745V436.428h-41.745V643.602z
					 M0.044,248.132l33.711,24.618l122.183-167.308l-33.712-24.618L0.044,248.132z M494.332,257.181h41.744V80.823h-41.744V257.181z
					 M275.69,522.418h62.044v79.438H275.69V522.418z M310.964,473.001l-35.274-53.576v-9.183h62.044v70.432h-38.423L310.964,473.001
					z M230.601,350.938h199.052v129.883l-50.174,81.229v-39.632v-41.744v-70.432v-41.743h-41.745H275.69h-33.528L230.601,350.938z
					 M275.69,172.655h9.297h41.743h11.004v136.539H275.69V172.655z M429.653,69.302v239.892h-50.174V172.655h25.56v-41.744H326.73
					V69.302h-41.743v61.609h-82.715v41.744h31.675v136.539h-30.829l-6.078-9.23l-34.864,22.954l30.008,45.581H79.079l95.413-130.65
					l-33.713-24.618L27.39,368.499H16.146v41.743h82.716v194.891h41.744V410.242h79.061l14.28,21.689v48.743v41.744v79.439v41.745
					h41.743h62.044h41.745v-2.118l50.174-81.23v41.603v41.745h41.744h63.667v-41.745h-63.667V492.675l59.963-97.077l-35.514-21.937
					l-24.449,39.581v-62.303h63.667v-41.744h-63.667V69.302H429.653z"/>
			</defs>
      <clipPath id="SVGID_2_">
				<use xlinkHref="#SVGID_1_" style={{overflow:"visible"}}/>
			</clipPath>
      <g className="st1">
				<g>
					<g>
						<defs>
							<rect id="SVGID_3_" x="-70.104" y="69.302" width="1199.751" height="674.86"/>
						</defs>
            <clipPath id="SVGID_4_">
							<use xlinkHref="#SVGID_3_" style={{overflow:"visible"}}/>
						</clipPath>
            <g transform="matrix(1 0 0 1 0 0)" className="st2">

								<image style={{overflow:"visible",enableBackground:"new"}} width="5783" height="3258"
                       xlinkHref={MainVisualImgBg02} transform="matrix(0.2071 0 0 0.2071 -69.1724 69.3025)">
							</image>
						</g>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
        <g>
	<g>
		<g>
			<defs>
				<path id="SVGID_5_" d="M661.648,193.614l-33.711-24.619L750.12,1.687l33.711,24.618L661.648,193.614z M938.857,393.866
					L824.933,220.828l-34.865,22.955l30.008,45.579H706.973l95.411-130.649l-33.712-24.618l-113.39,155.267H644.04v41.744h82.714
					v194.89h41.744v-194.89h79.061l56.432,85.715L938.857,393.866z M800.193,564.466h41.744V357.293h-41.744V564.466z
					 M527.831,26.306L494.12,1.687L371.937,168.995l33.711,24.619L527.831,26.306z M568.933,220.828l-34.865,22.955l30.008,45.579
					H450.973l95.411-130.649l-33.712-24.618l-113.39,155.267H388.04v41.744h82.714v194.89h41.744v-194.89h79.061l52.481,79.713
					v153.646h41.744V357.293h-27.006L568.933,220.828z M388.04,564.466h41.744V357.293H388.04V564.466z M544.193,564.466h41.744
					V357.293h-41.744V564.466z"/>
			</defs>
      <clipPath id="SVGID_6_">
				<use xlinkHref="#SVGID_5_" style={{overflow:"visible"}}/>
			</clipPath>
      <g className="st3">
				<g>
					<g>
						<defs>
							<rect id="SVGID_7_" x="38.536" y="-48.402" width="1234.781" height="694.564"/>
						</defs>
            <clipPath id="SVGID_8_">
							<use xlinkHref="#SVGID_7_" style={{overflow:"visible"}} />
						</clipPath>
            <g transform="matrix(1 0 0 1 0 0)" className="st4">

								<image style={{overflow:"visible",enableBackground:"new"}} width="5776" height="3258"
                       xlinkHref={MainVisualImgBg01} transform="matrix(0.2132 0 0 0.2132 40.2423 -48.4021)">
							</image>
						</g>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
</svg>
    </div>
  );
}

export default TopMainVisualImage
