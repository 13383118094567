import React, { useCallback, useContext, useEffect, useRef } from "react";
// import { useStaticQuery, graphql } from "gatsby";
import { TransitionPortal } from "gatsby-plugin-transition-link";
import { gsap } from "gsap";
import GlobalContext from "../context/globalContext";
import SEO from "../components/seo"
import TopMainVisual from "../components/TopMainVisual/topMainVisual"
import { indexStyle } from "../components/indexStyle";
import TransitionCover from "../components/TransitionCover/transitionCover";
import { duration, entryDelay } from "../constants/componentConstants";
import SideRibbon from "../components/SideRibbon/sideRibbon";
import IndexIntro from "../components/Index/IndexIntro";
import IndexVideo from "../components/Index/IndexVideo";
import IndexDesigner from "../components/Index/IndexDesigner";
import IndexEvent from "../components/Index/IndexEvent";

const IndexPage = () => {
  const global = useContext(GlobalContext);
  useEffect(() => {
    global.setHideHeaderLogo(true);
    global.setEntryAnimation(entryAnimation);
    global.setExitAnimation(exitAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);
  useEffect(() => {
    if (!global.isLoading) {
      console.log("Site Loaded");
    }
  }, [global.isLoading]);

  const commonLayer = useRef(null)
  const entryAnimation = useCallback(() => {
    console.log("entryAnimation");
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 100,
      })
      .to(commonLayer.current, {
        xPercent: 200,
        ease: "power2.in",
        duration: duration,
        delay: entryDelay,
        onComplete: () => {
          commonLayer.current.style.display = "none";
        }
      })
  }, []);
  const exitAnimation = useCallback(({ node }) => {
    console.log("exitAnimation");
    commonLayer.current.style.display = "block";
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 0,
      })
      .to(commonLayer.current, {
        xPercent: 100,
        ease: "power2.out",
        duration: duration,
      })
      .to(node, {
        opacity: 0,
        duration: 0,
      })
  }, []);

  return (
    <>
      <SEO title="" />

      <SideRibbon />

      <div css={indexStyle.mainVisualBlock}>
        <TopMainVisual />
      </div>

      <IndexIntro />

      <IndexEvent />

      <IndexVideo />

      <IndexDesigner entryAnimation={entryAnimation} exitAnimation={exitAnimation} />

      <TransitionPortal>
        <TransitionCover ref={commonLayer} />
      </TransitionPortal>
    </>
  )
}

export default IndexPage
