import React, { useContext, useEffect, useRef, useState } from "react"
import MainVisualAnimationImg from "../../images/top/main_visual_animation.svg";
import { gsap } from "gsap";
import GlobalContext from "../../context/globalContext";
import { topMainVisualStyle } from "./topMainVisualStyle";
import TopMainVisualImage from "./topMainVisualImage";
import LogoKawashimaFritzImg from "../../images/top/logo_kawashima_fritz.svg";

const TopMainVisual = () => {
  const global = useContext(GlobalContext);
  const [animateCompleted, setAnimateCompleted] = useState(false);

  const image01Ref = useRef(null);
  const image02Ref = useRef(null);

  useEffect(() => {
    gsap.set("#group01 .path_v", { yPercent: -1200 });
    gsap.set("#group02 .path_v", { yPercent: -1500 });
    gsap.set("#group03 .path_v", { yPercent: -1000 });
    gsap.set(".path_h", { xPercent: -1000 });
    gsap.set("#group01 .path_dl", { xPercent: 300, yPercent: -1500 });
    gsap.set("#group01 .path_dl2", { xPercent: 300, yPercent: -2400 });
    gsap.set("#group02 .path_dl", { xPercent: 400, yPercent: -2000 });
    gsap.set("#group03 .path_dl", { xPercent: 250, yPercent: -1250 });
    gsap.set(".path_dr", { xPercent: -300, yPercent: -1500 });
  }, []);

  useEffect(() => {
    if (!global.isLoading) {
      // console.log("Site Loaded");
      const duration = 3;
      const easing = "power3.out";
      gsap.to(
        "#group01 .path_v",
          { yPercent: 0, duration: duration, ease: easing }
        );
      gsap.to(
        "#group02 .path_v",
        { yPercent: 0, duration: duration, delay: 0.4, ease: easing }
      );
      gsap.to(
        "#group03 .path_v",
        { yPercent: 0, duration: duration, delay: 0.2, ease: easing }
      );
      gsap.to(
        "#group01 .path_h",
          { xPercent: 0, duration: duration * 0.9, ease: easing }
        );
      gsap.to(
        "#group02 .path_h",
        { xPercent: 0, duration: duration * 1, delay: 0.6, ease: easing }
      );
      gsap.to(
        "#group03 .path_h",
        { xPercent: 0, duration: duration * 0.8, delay: 0.4, ease: easing }
      );
      gsap.to(
        "#group01 .path_dl",
          { xPercent: 0, yPercent: 0, duration: duration * 0.8, ease: easing }
        );
      gsap.to(
        "#group01 .path_dl2",
        { xPercent: 0, yPercent: 0, duration: duration * 0.6, ease: easing }
      );
      gsap.to(
        "#group02 .path_dl",
          { xPercent: 0, yPercent: 0, duration: duration * 1, delay: 0.8, ease: easing }
        );
      gsap.to(
        "#group03 .path_dl",
        { xPercent: 0, yPercent: 0, duration: duration * 0.7, delay: 0.4, ease: easing }
      );
      gsap.to(
        "#group01 .path_dr",
          { xPercent: 0, yPercent: 0, duration: duration * 0.8, ease: easing }
        );
      gsap.to(
        "#group02 .path_dr",
        { xPercent: 0, yPercent: 0, duration: duration * 1, delay: 0.6, ease: easing }
      );
      gsap.to(
        "#group03 .path_dr",
        { xPercent: 0, yPercent: 0, duration: duration * 0.9, delay: 0.2, ease: easing }
      );
      const timer = setTimeout(() => {
        completeAnimationProcess();
      }, duration * 1.1 * 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [global.isLoading]);

  const completeAnimationProcess = () => {
    setAnimateCompleted(true);
  }

  // const onLoad = () => {
  //   console.log("loaded");
  //   refCount = refCount + 1;
  //   if (refCount === 2) {
  //     console.log(image01Ref.current);
  //     // console.log(image02Ref.current);
  //   }
  // };

  // if (!data?.mainVisual01?.childImageSharp?.fluid) {
  //   return <div>Picture not found</div>
  // }

  const mainImageClass = animateCompleted
    ? [topMainVisualStyle.mainImage, topMainVisualStyle.mainImageShow]
    : topMainVisualStyle.mainImage;
  const logoClass = animateCompleted
    ? [topMainVisualStyle.logoKawashimaFritzBlock, topMainVisualStyle.logoKawashimaFritzBlockShow]
    : topMainVisualStyle.logoKawashimaFritzBlock;

  return (
    <div css={topMainVisualStyle.block}>
      <div ref={image01Ref} css={topMainVisualStyle.mainImageAnimation}>
        <MainVisualAnimationImg />
      </div>
      <div ref={image02Ref} css={mainImageClass}>
        {/*<MainVisualImg />*/}
        <TopMainVisualImage />
      </div>
      <div css={logoClass}>
        <LogoKawashimaFritzImg title="川島織物 x FRITZ HANSEN" />
      </div>
    </div>
  );
}

export default TopMainVisual
