/** @jsx jsx */
import { css } from '@emotion/core';
import { color, mq } from "../../constants/styleConstants";

export const sideRibbonStyle = {
  block: css`
    position: absolute;
    background: ${color.pink};
    top: 0;
    left: 6.65%;
    width: 3.55vw;
    height: 1px;
    z-index: 1;
    mix-blend-mode: multiply;
    transform-origin: top center;
    will-change: transform;
    ${mq('medium')} {
      left: 6.62%;
      width: 3.45vw;
    }
  `,
}
