import React, { useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { indexStyle } from "../indexStyle";
import { useInView } from "react-intersection-observer";
import GlobalContext from "../../context/globalContext";

const IndexEvent = () => {
  const global = useContext(GlobalContext);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  });
  const titleRef = useRef(null);
  const item01Ref = useRef(null);
  const item02Ref = useRef(null);
  const item03Ref = useRef(null);

  useEffect(() => {
    // console.log("mount");
    gsap.set(titleRef.current, { opacity: 0, y: 40 });
    gsap.set(item01Ref.current, { opacity: 0, y: 40 });
    gsap.set(item02Ref.current, { opacity: 0, y: 40 });
    gsap.set(item03Ref.current, { opacity: 0, y: 40 });

  }, []);

  const easing = "power2.out";
  const duration = 1;
  const durationOpacity = 0.4;
  useEffect(() => {
    // console.log(inView);
    if (inView && !global.isLoading) {
      gsap.timeline()
        .to(titleRef.current, { opacity: 1, duration: durationOpacity, ease: easing })
        .to(titleRef.current, { y: 0, duration: duration, ease: easing }, "<");
      gsap.timeline()
        .to(item01Ref.current, { opacity: 1, duration: durationOpacity, delay: 0.4, ease: easing })
        .to(item01Ref.current, { y: 0, duration: duration, ease: easing }, "<");
      gsap.timeline()
        .to(item02Ref.current, { opacity: 1, duration: durationOpacity, delay: 0.6, ease: easing })
        .to(item02Ref.current, { y: 0, duration: duration, ease: easing }, "<");
      gsap.timeline()
        .to(item03Ref.current, { opacity: 1, duration: durationOpacity, delay: 0.8, ease: easing })
        .to(item03Ref.current, { y: 0, duration: duration, ease: easing }, "<");
    }
  }, [inView, titleRef, global]);

  return (
    <div css={indexStyle.eventBlock} ref={ref}>
      <div css={indexStyle.eventInner}>
        <h2 css={indexStyle.eventTitle} ref={titleRef}>ONLINE EVENT</h2>
        <div>
          <div css={indexStyle.eventItem} ref={item01Ref}>
            <h3>織物屋の試み展　其の二　<span>オンライントークイベント 1</span></h3>
            <dl>
              <dt>登壇者</dt><dd>三原康裕氏、<span>クリステル・コーシェ氏、</span><span>ロク・ファン氏</span></dd>
              <dt>日時</dt><dd>11月19日（木） 19:00～21:00</dd>
              <dt>概要</dt><dd>三原氏、コーシェ氏、ロク氏に、織物屋の試み展のファブリックデザインを紹介いただきます。</dd>
            </dl>
            <dl>
              <dt>視聴方法</dt><dd>ご視聴には事前申込が必要です。下記ボタンよりお申し込み下さい。</dd>
              <dt>定員</dt><dd>500人</dd>
            </dl>
            <a
              css={indexStyle.eventButton}
              href=""
              target="_blank"
              rel="noopener noreferrer">
              お申し込みはこちら
            </a>
          </div>

          <div css={indexStyle.eventItem} ref={item02Ref}>
            <h3>織物屋の試み展　其の二　<span>オンライントークイベント 2</span></h3>
            <div>「伝統の継承とイノベーション」</div>
            <dl>
              <dt>登壇者</dt><dd>三原康裕氏（ファッションデザイナー）、<span>石上純也氏（建築家）、</span><span>佐藤修（川島織物セルコン）</span></dd>
              <dt>日時</dt><dd>11月25日（水） 19:00～21:00</dd>
              <dt>概要</dt><dd>三原氏、石上氏、佐藤が、ファブリックや建築を通じ、伝統の継承と革新について語ります。</dd>
            </dl>
            <dl>
              <dt>視聴方法</dt><dd>ご視聴には事前申込が必要です。下記ボタンよりお申し込み下さい。</dd>
              <dt>定員</dt><dd>500人</dd>
            </dl>
            <a
              css={indexStyle.eventButton}
              href=""
              target="_blank"
              rel="noopener noreferrer">
              お申し込みはこちら
            </a>
          </div>

          <div css={indexStyle.eventItem} ref={item03Ref}>
            <h3>Web勉強会　これからの 建築 / <span>デザイン を考える Vol 5</span></h3>
            <dl>
              <dt>登壇者</dt><dd>南馬越一義氏（BEAMS創造研究所シニアクリエイティブディレクター）、<span>川島織物セルコン 織物屋の試み展担当</span></dd>
              <dt>日時</dt><dd>11月20日（金） 19:00～21:00</dd>
              <dt>概要</dt><dd>織物屋の試み展のデザイナーコーディネートを行った南馬越氏と当社の担当者が、イベント誕生の経緯や、デザイナーとの秘話を通して、これからの建築やデザインについて語ります。</dd>
            </dl>
            <dl>
              <dt>視聴方法</dt><dd>ご視聴には事前申込が必要です。下記ボタンよりお申し込み下さい。</dd>
              <dt>定員</dt><dd>500人</dd>
            </dl>
            <a
              css={indexStyle.eventButton}
              href=""
              target="_blank"
              rel="noopener noreferrer">
              お申し込みはこちら
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(IndexEvent)
