import React from "react";
import { blockTitleStyle } from "./blockTitleStyle";

const BlockTitle = React.forwardRef(({ children }, ref) => {
  return (
    <h2 css={blockTitleStyle.block} ref={ref}>{children}</h2>
  );
});

export default BlockTitle
