/** @jsx jsx */
import { css } from '@emotion/core';
import { enFont01, mq, jpFont01 } from "../constants/styleConstants";

export const indexStyle = {
  mainVisualBlock: css`
    max-width: 100rem;
    padding: 2rem 3.2% 0 2%;
    margin: 0 auto;
    overflow: hidden;
    svg {
      width: 100%;
      height: auto;
      overflow: visible;
    }
  `,
  introBlock: css`
    max-width: 80rem;
    margin: 2rem auto 4rem;
    ${mq('medium')} {
      margin-bottom: 8rem;
    }
  `,
  introLogo: css`
    width: 40%;
    margin-left: 1rem;
    opacity: 0;
    img, svg {
      width: 100%;
      height: auto;
    }
    ${mq('medium')} {
      width: 22%;
    }
  `,
  introInner: css`
    margin-top: 2rem;
    padding: 0 1.25rem;
    ${mq('medium')} {
      display: flex;
      padding: 0;
      & > div {
        &:first-of-type {
          flex: 1;
          margin-right: 4%;
          margin-left: 8%;
        }
        &:last-of-type {
          width: 35%;
          max-width: 22.25rem;
          margin-right: 2rem;
        }
      }
    }
    ${mq('large')} {
      & > div {
        &:first-of-type {
          margin-right: 5%;
          margin-left: 10%;
        }
        &:last-of-type {
          width: 40%;
          max-width: 22.25rem;
          margin-right: 2rem;
        }
      }
    }
  `,
  introTitle: css`
    padding-left: 1.25rem;
    max-width: 28rem;
    ${mq('medium')} {
      padding-left: 0;
    }
  `,
  introTitleV: css`
    //display: none;
    width: 90%;
    height: auto;
    ${mq('large')} {
      display: block;
      width: 100%;
      height: auto;
    }
  `,
  introDescriptionJp: css`
    ${jpFont01.light};
    padding-left: 10%;
    margin-top: 3.5rem;
    //text-align: justify;
    ${mq('medium')} {
      padding-left: 2.5rem;
    } 
  `,
  introDescriptionEn: css`
    padding-left: 10%;
    margin-top: 3rem;
    ${enFont01.bold};
    font-size: 0.875rem;
    //text-align: justify;
    ${mq('medium')} {
      padding-left: 2.5rem;
    } 
  `,
  introDataBlock: css`
    padding-left: 10%;
    ${mq('medium')} {
      padding-left: 0;
    } 
  `,
  introDate: css`
    ${enFont01.bold};
    font-size: 10.125vw;
    line-height: 1.2;
    margin-top: 4rem;
    span {
      display: inline-block;
      &:first-of-type {
        padding-right: 0.25em;
      }
    }
    ${mq('small')} {
      font-size: 2.5rem;
    }
    ${mq('medium')} {
      margin-top: -1.2vw;
      font-size: 4.6vw;
      text-align: right;
      span {
        &:first-of-type {
          padding-right: 0;
        }
      }
    } 
    ${mq('large')} {
      margin-top: -1.125rem;
      font-size: 2.875rem;
    }   
  `,
  introVenue: css`
    ${enFont01.bold};
    font-size: 0.875rem;
    margin-top: 0.125rem;
    ${mq('medium')} {
      font-size: 1rem;
      text-align: right;
      line-height: 1.2;
      margin-top: 0.5rem;
    }
    ${mq('large')} {
      font-size: 1.125rem;
      text-align: left;
    }  
  `,
  introHour: css`
    ${enFont01.bold};
    font-size: 0.875rem;
    ${mq('medium')} {
      font-size: 1rem;
      text-align: right;
      line-height: 1.2;
      margin-top: 0.5rem;
    }
    ${mq('large')} {
      font-size: 1.125rem;
      text-align: left;
    }  
  `,
  introListBlock: css`
    ${enFont01.light};
    font-size: 0.8125rem;
    margin-top: 3.5rem;
  `,
  introList: css`
    li {
      line-height: 1.8;
      margin-bottom: 0.75rem;
    }
    ${mq('small2')} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 47%;
      }
    }
    ${mq('medium')} {
      display: block;
      li {
        width: 100%;
      }
    } 
  `,
  // introListItemSecond: css`
  //   padding-left: 1rem;
  //   display: inline-block;
  // `,
  introListItemJp: css`
    ${jpFont01.light};
  `,
  videoBlock: css`
    position: relative;
    z-index: 1;
  `,
  designerBlock: css`
    margin-top: 6rem;
    padding: 0 1.25rem;
    overflow: hidden; // important
    ${mq('medium')} {
      margin-top: 8rem;
    }
  `,
  designerList: css`
    max-width: 80rem;
    margin: -2rem auto 3rem;
    ${mq('small')} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    ${mq('medium')} {
      display: flex;
      margin-bottom: 8rem;
    }
  `,
  designerListItem: css`
    padding: 0 1%;
    width: 80%;
    max-width: 24rem;
    margin: 0 auto 10%;
    display: block;
    img {
      width: 100%;
    }
    & > div {
      ${enFont01.bold};
      font-size: 1.5rem;
      span {
        font-size: 1rem;
        display: block;
        margin-top: 0.5rem;
        ${jpFont01.bold};
      }
      &:first-of-type {
        transition: transform 0.6s ease;
      }
    }
    &:hover {
      & > div:first-of-type {
        transform: scale(1.04);
      }
    }
    ${mq('small')} {
      width: 50%;
      max-width: 100%;
      margin-bottom: 5%;
    }
    ${mq('medium')} {
      width: 33.333%;
      max-width: 100%;
      margin-bottom: 0;
      & > div {
        font-size: 1.875rem;
        span {
          font-size: 1.0625rem;
        }
      }
    }
  `,
  designerListImage: css`
    margin: 0 -5% 10%;
    position: relative;
    z-index: 0;
    ${mq('medium')} {
        margin: 0 -5% 5%;
    }
  `,
  designerListTitle: css`
    position: relative;
    z-index: 1;
    line-height: 1.2;
    ${mq('small')} {
      padding-left: 10%;
    }
  `,
  eventBlock: css`
    max-width: 80rem;
    margin: 6rem auto 4rem;
    ${mq('medium')} {
      margin-top: 2rem;
      margin-bottom: 8rem;
    }
  `,
  eventInner: css`
    margin-top: 2rem;
    padding: 0 1.25rem;
    ${mq('medium')} {
      padding: 0;
      & > div, h2 {
        margin-left: 8%;
      }
    }
    ${mq('large')} {
      & > div, h2 {
        margin-left: 10%;
      }
    }
  `,
  eventTitle: css`
    ${enFont01.bold};
    font-size: 1.875rem;
    padding-left: 1.25rem;
  `,
  eventItem: css`
    padding: 3rem 0 2rem 10%;
    max-width: 60rem;
    h3 {
      ${jpFont01.bold};
      font-size: 1.125rem;
      margin-bottom: 2rem;
      letter-spacing: 0.05em;
      span {
        display: inline-block;
      }
    }
    dl {
      margin-bottom: 1rem;
      ${jpFont01.light};
      font-size: 0.875rem;
    }
    dt {
      width: 5rem;
      letter-spacing: 0.1em;
      span {
        display: inline-block;
      }
      &:after {
        content: ":";
        width: 1rem;
        display: inline-block;
      }
      ${mq('medium')} {
        width: 7rem;
        &:after {
          width: 2rem;
        }
      }
    }
    dd {
      margin-bottom: 1rem;
      span {
        display: inline-block;
      }
      ${mq('medium')} {
        width: calc(100% - 7rem);
      }
    }
    ${mq('medium')} {
      padding-left: 2.5rem;
      dl {
        display: flex;
        flex-wrap: wrap;
      }
      dd {
        margin-bottom: 0;
        width: calc(100% - 5rem);
      }
    } 
  `,
  eventButton: css`
    display: block;
    text-align: center;
    ${jpFont01.bold};
    border: 1px solid #000;
    background: #fff;
    color: #000;
    width: 14rem;
    height: 2.625rem;
    line-height: 2.5rem;
    letter-spacing: 0.05em;
    margin-top: 1.5rem;
    transition: color .3s ease;
    position: relative;
    z-index: 1;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      background: #000;
      transform-origin: right top;
      transform: scale(0, 1);
      transition: transform .5s cubic-bezier(0.33, 1, 0.68, 1);
    }
    &:hover {
      color: #fff;
      opacity: 1;
      border-color: #000;
      transition: color .2s ease;
      &:before {
        transform-origin: left top;
        transform: scale(1, 1);
        transition: transform .3s cubic-bezier(0.33, 1, 0.68, 1);
      }
    }
  `,
}
