import React, { useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { indexStyle } from "../indexStyle";
import { useInView } from "react-intersection-observer";
import GlobalContext from "../../context/globalContext";
import BlockTitle from "../BlockTitle/blockTitle";
import TransitionLink from "gatsby-plugin-transition-link";
import { duration, imageFadeDuration } from "../../constants/componentConstants";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const IndexDesigner = ({entryAnimation, exitAnimation}) => {
  const data = useStaticQuery(graphql`
  query {
    designer01: file(relativePath: { eq: "designer01/image01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    designer02: file(relativePath: { eq: "designer02/image01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    designer03: file(relativePath: { eq: "designer03/image01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`);

  const global = useContext(GlobalContext);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
    triggerOnce: true,
  });
  const titleRef = useRef(null);
  const item01Ref = useRef(null);
  const item02Ref = useRef(null);
  const item03Ref = useRef(null);

  useEffect(() => {
    // console.log("mount");
    gsap.set(titleRef.current, { opacity: 0, y: 40 });
    gsap.set(item01Ref.current, { opacity: 0, y: 40 });
    gsap.set(item02Ref.current, { opacity: 0, y: 40 });
    gsap.set(item03Ref.current, { opacity: 0, y: 40 });

  }, []);

  const easing = "power2.out";
  const effectDuration = 1;
  const durationOpacity = 0.4;
  useEffect(() => {
    // console.log(inView);
    if (inView && !global.isLoading) {
      gsap.timeline()
        .to(titleRef.current, { opacity: 1, duration: durationOpacity, ease: easing }, "base")
        .to(titleRef.current, { y: 0, duration: effectDuration, ease: easing }, "<")
        .to(item01Ref.current, { opacity: 1, duration: durationOpacity, ease: easing }, "base+=0.4")
        .to(item01Ref.current, { y: 0, duration: effectDuration, ease: easing }, "<")
        .to(item02Ref.current, { opacity: 1, duration: durationOpacity, ease: easing }, "base+=0.5")
        .to(item02Ref.current, { y: 0, duration: effectDuration, ease: easing }, "<")
        .to(item03Ref.current, { opacity: 1, duration: durationOpacity, ease: easing }, "base+=0.6")
        .to(item03Ref.current, { y: 0, duration: effectDuration, ease: easing }, "<");
    }
  }, [inView, titleRef, global]);

  return (
    <div css={indexStyle.designerBlock} ref={ref}>
      <BlockTitle ref={titleRef}>Designer</BlockTitle>
      <div css={indexStyle.designerList}>
        <TransitionLink
          css={indexStyle.designerListItem}
          to="/designer-mihara-yasuhiro/"
          exit={{
            trigger: exitAnimation,
            length: duration * 3
          }}
          entry={{
            trigger: entryAnimation,
            delay: duration, // 重要 これがないとtopにジャンプする
            length: duration * 2
          }}
          ref={item01Ref}
        >
          <div css={indexStyle.designerListImage}>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.designer01.childImageSharp.fluid}
            />
          </div>
          <div css={indexStyle.designerListTitle}>
            1. MIHARA YASUHIRO
            <span>三原康裕</span>
          </div>
        </TransitionLink>
        <TransitionLink
          css={indexStyle.designerListItem}
          to="/designer-christelle-kocher/"
          exit={{
            trigger: exitAnimation,
            length: duration * 3
          }}
          entry={{
            trigger: entryAnimation,
            delay: duration, // 重要 これがないとtopにジャンプする
            length: duration * 2
          }}
          ref={item02Ref}
        >
          <div css={indexStyle.designerListImage}>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.designer02.childImageSharp.fluid}
            />
          </div>
          <div css={indexStyle.designerListTitle}>
            2. CHRISTELLE KOCHER
            <span>クリステル・コシェ</span>
          </div>
        </TransitionLink>
        <TransitionLink
          css={indexStyle.designerListItem}
          to="/designer-rok-hwang/"
          exit={{
            trigger: exitAnimation,
            length: duration * 3
          }}
          entry={{
            trigger: entryAnimation,
            delay: duration, // 重要 これがないとtopにジャンプする
            length: duration * 2
          }}
          ref={item03Ref}
        >
          <div css={indexStyle.designerListImage}>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.designer03.childImageSharp.fluid}
            />
          </div>
          <div css={indexStyle.designerListTitle}>
            3. ROK HWANG
            <span>ロク・ファン</span>
          </div>
        </TransitionLink>
      </div>
    </div>
  );
}

export default React.memo(IndexDesigner);
