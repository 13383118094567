import React, { useCallback, useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useInView } from "react-intersection-observer";
import GlobalContext from "../../context/globalContext";
import VideoFullWidth from "../Video/videoFullWidth";
import { graphql, useStaticQuery } from "gatsby";
import { indexStyle } from "../indexStyle";

const IndexVideo = () => {
  const data = useStaticQuery(graphql`
  query {
    video01: file(relativePath: { eq: "top/video01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`);

  const global = useContext(GlobalContext);
  const videoDelayValue = global.isLoading ? 1000 : 3000;
  const videoDelay = useRef(videoDelayValue);

  const ref = useRef(null);
  const [inViewRef, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    gsap.set(ref.current, { opacity: 0, y: 40 });
  }, []);

  const easing = "power2.out";
  const duration = 1;
  const durationOpacity = 0.4;
  useEffect(() => {
    if (inView && !global.isLoading) {
      gsap.timeline()
        .to(ref.current, { opacity: 1, duration: durationOpacity, ease: easing })
        .to(ref.current, { y: 0, duration: duration, ease: easing }, "<");
    }
  }, [inView, ref, global]);

  return (
    <div ref={setRefs} css={indexStyle.videoBlock}>
      <VideoFullWidth
        videoId="64083500"
        image={data.video01}
        title="xxxxxxxxx"
        delay={videoDelay.current}
      />
    </div>
  );
}

export default IndexVideo
