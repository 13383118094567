import React, { useCallback, useContext, useEffect, useRef } from "react";
import { sideRibbonStyle } from "./sideRibbonStyle";
import { gsap } from "gsap";
import { waitForFinalEvent } from "../../utilities/scrollUtility";
import GlobalContext from "../../context/globalContext";

const SideRibbon = (props) => {
  const ribbonRef = useRef(null);
  const global = useContext(GlobalContext);

  const handleScroll = useCallback(() => {
    waitForFinalEvent(function(){
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let targetScale = 0;
      // let delta = 0;
      if (preScrollPos > scrollTop) {
        targetScale = scrollTop;
        // delta = window.innerHeight;
      } else {
        targetScale = scrollTop + window.innerHeight;
        // delta = targetScale - preScrollPos;
      }

      const duration = 2;
      // const duration = 1.2 * (delta / window.innerHeight);
      gsap
        .to(ribbonRef.current,
          {
            scaleY: targetScale,
            duration: duration,
            ease: "power1.out",
          }
        );
      preScrollPos = scrollTop;
    },300,"side ribbon scroll");
  });

  let preScrollPos = 0;
  useEffect(() => {
    gsap
      .to(ribbonRef.current,
        {
          scaleY: 0,
          duration: 2,
          ease: "power2.inOut",
        }
      );
    return () => {
      console.log("unmount side ribbon");
      window.removeEventListener('scroll', handleScroll, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);
  useEffect(() => {
    if (!global.isLoading) {
      // console.log("Site Loaded");
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      gsap
        .to(ribbonRef.current,
          {
            scaleY: scrollTop + window.innerHeight,
            duration: 2,
            ease: "power2.inOut",
          }
        );
      window.addEventListener('scroll', handleScroll, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [global.isLoading]);

  return (
    <div css={sideRibbonStyle.block} ref={ribbonRef}>
      {props.children}
    </div>
  );
};

export default SideRibbon
