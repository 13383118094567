import React, { useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import LogoImg from "../../images/logo.svg";
import { indexStyle } from "../indexStyle";
import TitleVImg from "../../images/title_v_gold.svg";
import { useInView } from "react-intersection-observer";
import GlobalContext from "../../context/globalContext";

const IndexIntro = () => {
  const global = useContext(GlobalContext);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
    triggerOnce: true,
  });
  const logoRef = useRef(null);
  const titleRef = useRef(null);
  const dataRef = useRef(null);

  useEffect(() => {
    // console.log("mount");
    gsap.set(logoRef.current, { opacity: 0, y: 40 });
    gsap.set(titleRef.current, { opacity: 0, y: 40 });
    gsap.set(dataRef.current, { opacity: 0, y: 40 });

  }, []);

  const easing = "power2.out";
  const duration = 1;
  const durationOpacity = 0.4;
  useEffect(() => {
    // console.log(inView);
    if (inView && !global.isLoading) {
      gsap.timeline()
        .to(logoRef.current, { opacity: 1, duration: durationOpacity, ease: easing })
        .to(logoRef.current, { y: 0, duration: duration, ease: easing }, "<");
      gsap.timeline()
        .to(titleRef.current, { opacity: 1, duration: durationOpacity, delay: 0.4, ease: easing })
        .to(titleRef.current, { y: 0, duration: duration, ease: easing }, "<");
      gsap.timeline()
        .to(dataRef.current, { opacity: 1, duration: durationOpacity, delay: 0.6, ease: easing })
        .to(dataRef.current, { y: 0, duration: duration, ease: easing }, "<");
    }
  }, [inView, logoRef, global]);

  return (
    <div css={indexStyle.introBlock} ref={ref}>
      <div css={indexStyle.introLogo} ref={logoRef}>
        <LogoImg title="Weaver's QUEST" />
      </div>
      <div css={indexStyle.introInner}>
        <div ref={titleRef}>
          <h1 css={indexStyle.introTitle}>
            <TitleVImg css={indexStyle.introTitleV} title="織物屋の試み 展 其の二 ファッションデザイナー編" />
          </h1>
          <div css={indexStyle.introDescriptionJp}>
            川島織物セルコンが、織物の未来と100年後の川島織物セルコンを考える活動のひとつとして、2019年に始動した「織物屋の試み」展。二回目の開催となる今年は、三原康裕（MAISON MIHARA YASUHIRO）、クリステル・コシェ（KOCHÉ）、ロク・ファン（ROKH）の三名のファッションデザイナーとコラボレーションを実施。彼らがデザインした織物を、北欧家具メーカー「フリッツ・ハンセン（Fritz Hansen）」を代表するエッグチェアに張り込み、唯一無二の作品が完成した。これらの作品は、フリッツ・ハンセン青山本店にて2020年11月18日（火）〜29日（日）まで期間限定で展示される。
          </div>
          <div css={indexStyle.introDescriptionEn}>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
          </div>
        </div>

        <div css={indexStyle.introDataBlock} ref={dataRef}>
          <div css={indexStyle.introDate}>
            <span>2020.11.18(Wed)</span>
            <span>- 11.29(Sun)</span>
          </div>
          <div css={indexStyle.introVenue}>
            Venue : FRITZ HANSEN STORE AOYAMA
          </div>
          <div css={indexStyle.introHour}>Open hours: 12:00 - 18:00</div>
          <div css={indexStyle.introListBlock}>
            <ul css={indexStyle.introList}>
              <li>Organizer | Kawashima Selkon Textiles Co.,LTD.</li>
              <li>
                Planning Cooperation | Dramatic Co., Ltd.,<br />
                <span css={indexStyle.introListItemSecond}>
                  Kazuyoshi Minamimagoe
                  <span css={indexStyle.introListItemJp}>(ビームス創造研究所)</span>
                </span>
              </li>
              <li>Space Design | VISIONS AND PARADOX CO.,LTD.</li>
              <li>Graphic Design | apart-apart inc.</li>
              <li>Movie Design | Shinshun inc.</li>
              <li>Cooperation | FRITZ HANSEN</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexIntro
